<template>
  <div>
    <div class="header">
      <img width="100%" src="../../assets/mobileInfo/header.png" alt="" />
      <div class="header-box">
        <h1>{{ this.$t("header.information") }}</h1>
      </div>
    </div>

    <div class="banner">
      <h1>01</h1>
      <div class="banner-box">
        <h2>{{ this.$t("Information.Mission.title") }}</h2>
        <p>{{ this.$t("Information.Mission.content") }}</p>
      </div>
    </div>
    <div class="introduce">
      <h1>02</h1>
      <div class="introduce-box">
        <h2>{{ this.$t("Information.Vision.title") }}</h2>
        <p>{{ this.$t("Information.Vision.content") }}</p>
      </div>
    </div>
    <div class="milestone">
      <img
        width="100%"
        v-show="_choose === 'JP'"
        src="../../assets/mobileInfo/JP-img.png"
        alt=""
      />
      <img
        width="100%"
        v-show="_choose === 'CH'"
        src="../../assets/mobileInfo/CH-img.png"
        alt=""
      />
      <img
        width="100%"
        v-show="_choose === 'EN'"
        src="../../assets/mobileInfo/EN-img.png"
        alt=""
      />
    </div>
    <ul class="info">
      <li>
        <h3>{{ this.$t("Information.setiona_title") }}</h3>
        <p>{{ this.$t("Information.setion_a") }}</p>
      </li>
      <li>
        <h3>{{ this.$t("Information.setionb_title") }}</h3>
        <p>{{ this.$t("Information.setion_b") }}</p>
      </li>
      <li>
        <h3>{{ this.$t("Information.setionc_title") }}</h3>
        <p>{{ this.$t("Information.setion_c") }}</p>
      </li>
      <li>
        <h3>{{ this.$t("Information.setione_title") }}</h3>
        <p>{{ this.$t("Information.setion_e") }}</p>
      </li>
      <li>
        <h3>{{ this.$t("Information.setionf_title") }}</h3>
        <p>{{ this.$t("Information.setion_f") }}</p>
      </li>
      <li>
        <h3>{{ this.$t("Information.setiong_title") }}</h3>
        <p>{{ this.$t("Information.setion_g") }}</p>
      </li>
    </ul>
    <div class="map">
      <iframe class="map-img"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3244.754932222781!2d139.72886591493173!3d35.584440180216916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601861b63984bf67%3A0x9993024f54300259!2z5qCq5byP5Lya56S-44Ko44Oz44Km44Kn44Kk!5e0!3m2!1sja!2sjp!4v1679040746379!5m2!1sja!2sjp"
            width="100%"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
      <div class="map-button" @click="gomap">
        <img src="../../assets/mobileInfo/map@2x.png" alt="" />
        {{ this.$t("Information.setion_h") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  computed: {
    _choose() {
      return this.$parent.$parent.$children[0].$data.choose;
    },
  },
  mounted() {},
  methods: {
    gomap() {
      window.location.href =
        "https://www.google.com/maps/place/%E3%80%92143-0016+Tokyo,+Ota+City,+%C5%8Cmorikita,+3-ch%C5%8Dme%E2%88%921%E2%88%922+%EF%BC%AB%EF%BC%AB%EF%BC%B3%E3%83%93%E3%83%AB/@35.5868396,139.7288015,17z/data=!4m5!3m4!1s0x60186034a12acbff:0x148c61e3bb4a4928!8m2!3d35.5844402!4d139.7310546";
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  img {
    vertical-align: bottom;
  }
  .header-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
    height: 52px;
    background: rgba(255, 255, 255, 0.39);
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 20px;
      font-family: 'Meiryooo';
      font-weight: bold;
      line-height: 28px;
      color: #48464b;
    }
  }
}
.banner {
  width: 100vw;
  height: 56vw;
  background: url("../../assets/mobileInfo/banner.png") no-repeat center;
  background-size: cover !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 4.2667vw;
    font-family: 'Meiryooo';
    font-weight: bold;
    line-height: 5.8667vw;
    color: #0064e6;
    text-align: center;
    margin: 5.3333vw 0 2.6667vw 0;
    flex-shrink: 0;
  }
  .banner-box {
    padding: 0 4.2667vw;
    h2 {
      font-size: 4.2667vw;
      font-family: 'Meiryooo';
      font-weight: bold;
      line-height: 5.8667vw;
      color: #000000;
      margin-bottom: 11.2vw;
      text-align: center;
    }
    p {
      font-size: 3.2vw;
      font-family: 'Meiryooo';
      font-weight: 400;
      line-height: 6.9333vw;
      color: #333333;
    }
  }
}
.introduce {
  width: 100vw;
  height: 56vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 4.2667vw;
    font-family: 'Meiryooo';
    font-weight: bold;
    line-height: 5.8667vw;
    color: #0064e6;
    text-align: center;
    margin: 5.3333vw 0 2.6667vw 0;
  }
  .introduce-box {
    padding: 0 4.2667vw;
    h2 {
      font-size: 4.2667vw;
      font-family: 'Meiryooo';
      font-weight: bold;
      line-height: 5.8667vw;
      color: #000000;
      margin-bottom: 11.2vw;
      text-align: center;
    }
    p {
      font-size: 3.2vw;
      font-family: 'Meiryooo';
      font-weight: 400;
      line-height: 6.9333vw;
      color: #333333;
    }
  }
}
.info {
  padding: 0 4.2667vw;
  margin: 10.6667vw 0;
  li {
    display: flex;
    // align-items: center;
    margin-bottom: 20px;
    h3 {
      width: 22.4vw;
      flex-shrink: 0;
      white-space: nowrap;
      font-size: 3.2vw;
      font-family: 'Meiryooo';
      font-weight: bold;
      line-height: 6.9333vw;
      color: #000000;
      text-align: right;
      margin-right: 4.2667vw;
    }
    p {
      font-size: 3.2vw;
      flex-grow: 1;
      font-family: 'Meiryooo';
      font-weight: 400;
      line-height: 6.9333vw;
      color: #333333;
    }
  }
  li:first-child {
    h3 {
      white-space: nowrap;
    }
  }
  li:last-child {
    margin: 0;
  }
}
.map {
  padding: 0 4.2667vw;
  .map-img {
    width: 91.4667vw;
    height: 53.3333vw;
    background-position: 0 -32vw;
    margin-bottom: 3.2vw;
  }
  .map-button {
    width: 91.4667vw;
    height: 13.8667vw;
    background-color: #edecec;
    font-size: 3.7333vw;
    font-family: 'Meiryooo';
    font-weight: 400;
    line-height: 5.3333vw;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16vw;
    img {
      width: 3.7333vw;
      height: 4.2667vw;
      margin-right: 3.2vw;
    }
  }
}
</style>