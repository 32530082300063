<template>
  <div>
    <div class="banner">
      <!-- <img width="100%" src="@/assets/information/组 2742.png" alt="" /> -->
      <div class="jisuke">{{ this.$t("header.information") }}</div>
    </div>
    <div class="history">
      <div class="historicalProcess history1">
        <img width="100%" src="@/assets/information/ミッション.png" alt="" />
        <div class="course course1 container">
          <div class="left">
            <div class="num">01</div>
            <div class="title">{{ this.$t("Information.Mission.title") }}</div>
          </div>
          <div class="right">
            <div class="blue"><span></span></div>
            <div class="courseContent">
              <p>{{ this.$t("Information.Mission.content") }}</p>
              <p>
                {{ this.$t("Information.Mission.pcdetails") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="historicalProcess history2">
        <div class="course course1 container">
          <div class="right">
            <div class="blue"><span></span></div>
            <div class="courseContent">
              <p>
                {{ this.$t("Information.Vision.content") }}
              </p>
              <p>
                {{ this.$t("Information.Vision.pcdetails") }}
              </p>
            </div>
          </div>
          <div class="left">
            <div class="num">02</div>
            <div class="title">{{ this.$t("Information.Vision.title") }}</div>
          </div>
        </div>
      </div>
      <!-- _laguage -->
      <div v-show="_laguage === 'JP'" class="history3 jp"></div>
      <div v-show="_laguage === 'CH'" class="history3 ch"></div>
      <div v-show="_laguage === 'EN'" class="history3 en"></div>
    </div>

    <div class="content container">
      <ul>
        <li>
          <span class="textRight">{{
            this.$t("Information.setiona_title")
          }}</span>
          <p>{{ this.$t("Information.setion_a") }}</p>
        </li>
        <li>
          <span class="textRight">{{
            this.$t("Information.setionb_title")
          }}</span>
          <p>{{ this.$t("Information.setion_b") }}</p>
        </li>
        <li>
          <span class="textRight">{{
            this.$t("Information.setionc_title")
          }}</span>
          <p>{{ this.$t("Information.setion_c") }}</p>
        </li>
        <li>
          <span class="textRight">{{
            this.$t("Information.setione_title")
          }}</span>
          <p>{{ this.$t("Information.setion_e") }}</p>
        </li>
        <li>
          <span class="textRight">{{
            this.$t("Information.setionf_title")
          }}</span>
          <p>{{ this.$t("Information.setion_f") }}</p>
        </li>
        <li>
          <span class="textRight">{{
            this.$t("Information.setiong_title")
          }}</span>
          <p>{{ this.$t("Information.setion_g") }}</p>
        </li>
      </ul>
      <div class="map">
        <div class="map-img">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3244.754932222781!2d139.72886591493173!3d35.584440180216916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601861b63984bf67%3A0x9993024f54300259!2z5qCq5byP5Lya56S-44Ko44Oz44Km44Kn44Kk!5e0!3m2!1sja!2sjp!4v1679040746379!5m2!1sja!2sjp"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <p class="map-text">{{ this.$t("Information.setion_c") }}</p>
        <div @click="gomap" class="gomap">
          <i class="icon-map"></i>
          <span>{{ this.$t("Information.setion_h") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentList: [
        { id: 0, title: "企　業　名　:", content: "株式会社エンウェイ" },
        { id: 1, title: "設　立　日　:", content: "2015年(平成27年)5月15日" },
        {
          id: 2,
          title: "所　在　地　:",
          content: "大森 〒143-0016 ：東京都大田区大森北3-1-2 KKSビル4F",
        },
        {
          id: 3,
          title: "取 引 先 銀 行 :",
          content: "三菱UFJ銀行/みずほ銀行",
        },
        { id: 4, title: "代 表 取 締 役 :", content: "劉　志鎖" },
        { id: 5, title: "固 定 電 話 :", content: "03 6404 6200" },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {
    _laguage() {
      return this.$parent.$parent.$children[0].$data.choose;
    }
  },
  methods: {
    gomap() {
      window.open(
        "https://www.google.com/maps/place/%E3%80%92143-0016+Tokyo,+Ota+City,+%C5%8Cmorikita,+3-ch%C5%8Dme%E2%88%921%E2%88%922+%EF%BC%AB%EF%BC%AB%EF%BC%B3%E3%83%93%E3%83%AB/@35.5868396,139.7288015,17z/data=!4m5!3m4!1s0x60186034a12acbff:0x148c61e3bb4a4928!8m2!3d35.5844402!4d139.7310546"
      );
    },
  },
};
</script>

<style scoped lang="less">
.banner {
  position: relative;
  height: 588px;
  background: url('../../assets/information/组 2742.png') no-repeat center;
  img {
    vertical-align: bottom;
  }

  .jisuke {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 120px;
    background: rgba(255, 255, 255, 0.39);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 44px;
    font-family: 'meiryooo';
    font-weight: bold;
    line-height: 59px;
    color: #48464b;
  }
}

.history {
  .historicalProcess {
    position: relative;

    img {
      vertical-align: bottom;
    }

    .course {
      width: 1200px;
      height: 100%;
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100%;

        .num {
          font-size: 50px;
          color: #0064e6;
          font-family: 'meiryooo';
        }

        .title {
          font-size: 55px;
          font-family: 'meiryooo';
          font-weight: bold;
          line-height: 80px;
          color: #000000;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100%;

        .blue {
          margin-bottom: 50px;

          span {
            display: inline-block;
            width: 80px;
            height: 4px;
            background-color: #0064e6;
          }
        }

        .courseContent {
          display: block;

          p {
            margin-bottom: 40px;
            font-size: 16px;
            font-family: 'meiryooo';
            font-weight: 400;
            line-height: 30px;
            color: #000000;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .history2 {
    height: 624px;

    .left {
      text-align: right;
    }
  }

  .history3 {
    background-size: cover !important;
    aspect-ratio: calc(1920 / 1526);
  }
  .jp {
    background: url("../../assets/information/JP-img.png") no-repeat center;
  }
  .ch {
    background: url("../../assets/information/CH-img.png") no-repeat center;
  }
  .en {
    background: url("../../assets/information/EN-img.png") no-repeat center;
  }
}

.content {
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  margin-top: 100px !important;
  margin-bottom: 100px !important;

  ul {
    width: 45%;
    height: 424px;
    // margin: auto 0 auto 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    li {
      width: 100%;
      display: flex;
      font-size: 18px;
      font-family: 'meiryooo';

      span {
        margin-right: 22px;
        width: 34%;
        text-align: right;
        flex-shrink: 0;
        font-weight: 550;
        color: #666;
      }

      p {
        width: 55%;
        color: #333;
      }
    }
  }

  .map {
    .map-img {
      background-color: #edecec;
      box-sizing: border-box;
      margin-bottom: 20px;

      img {
        vertical-align: bottom;
      }
    }

    .map-text {
      margin-bottom: 20px;
      font-size: 14px;
      font-family: 'meiryooo';
      line-height: 1.2;
    }

    .gomap {
      cursor: pointer;
      width: 100%;
      height: 60px;
      background-color: #edecec;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-map {
        width: 16px;
        height: 16px;
        background-color: #a8a8a8;
        border-radius: 16px;
        border-bottom-left-radius: 0;
        transform: rotate(-45deg);
        margin-right: 30px;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: inline-block;
          width: 6px;
          height: 6px;
          // border: 2px solid currentcolor;
          border-radius: 6px;
          background-color: #f2f5f8;
        }
      }

      span {
        font-size: 16px;
        font-family: 'meiryooo';
        font-weight: 400;
        line-height: 21px;
        color: #333333;
      }
    }
  }
}
</style>
